.ui.visible.right.overlay.sidebar {
  width: 340px;
}

.overdue {
  color: #FF4949;
}

.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.max-viewport-height {
  max-height: 100vh;
}

#root {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
#root .app-wrapper {
  width: 100%;
  background-color: white;
  /*
        @media(min-width: 768px) {
          &:not(.no-attribution):after {
            content: "Wineglass Bay, Tasmania";
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 10px;
            font-size: 12px;
            color: rgba(255, 255, 255, .70);
          }
        }
  */
}
@media (max-width: 767px) {
  #root .app-wrapper.hide-custom-background-mobile {
    background-image: none !important;
    background-color: white;
  }
}
#root .app-wrapper.is-authenticated:not(.custom-background):not(.white-page) {
  background-size: contain, contain, cover;
}
#root .app-wrapper.is-authenticated:not(.custom-background):not(.white-page):after {
  content: none;
}
#root .app-wrapper.white-page {
  background: white;
}
@media (min-width: 768px) {
  #root .app-wrapper.white-page:after {
    content: none;
  }
}
#root .app-wrapper.is-loading {
  background-image: none;
}
@media (max-width: 767px) {
  #root .app-wrapper.is-loading {
    background-image: url("static/backgrounds/bg-loading.png");
  }
}
#root .app-wrapper > .pushable {
  display: flex;
  flex-direction: column;
}
#root .app-wrapper > .pushable > .pusher {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 767px) {
  #root .app-wrapper.is-loading {
    background-image: url("static/backgrounds/bg-loading.png");
  }
}
.greeting {
  text-align: left;
}

.ui.container.main-container {
  flex: 1 0 auto;
  margin-top: 0px;
}
@media (max-width: 991px) {
  .ui.container.main-container {
    padding-bottom: 64px;
  }
}

.ui.layout-default.main-wrapper.logged-out {
  margin-top: 3em;
}
@media (max-width: 991px) {
  .ui.layout-default.main-wrapper.logged-out {
    margin-top: 0;
    padding: 0 1em;
    background: none;
    border: none;
    box-shadow: none;
    color: white;
  }
  .ui.layout-default.main-wrapper.logged-out .ui.header {
    color: white;
    border: none;
    margin-top: -0.5em;
  }
}

.password-strength {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  display: block;
}
.password-strength:after {
  display: block;
  content: "";
  height: 8px;
  width: 10%;
  background: #d0021b;
}
.password-strength.strong:after {
  background: #c6cc32;
  width: 55%;
}
.password-strength.excellent:after {
  background: #99cc32;
  width: 96%;
}

ul.ui.list li::before {
  content: "";
  width: 4px;
  height: 4px;
  background: #999999;
  border-radius: 50%;
  position: absolute;
  transform: translateY(0.5em);
}

.ui.list.accounts-list .item > .header {
  background: #0060ae;
  color: white;
  padding: 0.5em 1em;
  font-weight: normal;
  font-size: 0.8125em;
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ui.list.accounts-list .item > .header .label {
  padding-left: 0;
  padding-right: 0;
}
.ui.list.accounts-list .item > .grid {
  margin-left: 0;
  margin-right: 0;
  background: #f8f8f8;
  margin-bottom: 0;
}

.other-charges-tooltip.ui.table {
  padding: 0;
  font-size: 12px;
  border-radius: 0px;
  border: none;
  box-shadow: inset 0 4px 7px -5px rgba(0, 0, 0, 0.7);
}

.usage-summary-table .other-charges-tooltip.ui.table td {
  padding-left: 25px;
  border-bottom: 1px #ddd solid;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ui.popup.visible {
  padding: 0;
  font-size: 0.9rem;
}
@media (min-width: 992px) {
  .ui.popup.visible {
    min-width: 280px;
  }
}
.ui.popup.visible:before {
  background-color: #efefef !important;
}
.ui.popup.visible.center.solar:before {
  top: 75%;
}
.ui.popup.visible .header {
  background-color: #0060AE;
  color: white;
  padding: 0.5em 1em;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 1em;
}
.ui.popup.visible .content {
  background-color: #efefef;
  color: #4a4a4a;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em 1em;
}
.ui.popup.visible .content dt, .ui.popup.visible .content dd {
  width: 50%;
  min-width: 75px;
  font-size: 1em;
  line-height: 1.2;
  white-space: nowrap;
}
.ui.popup.visible .content dd {
  font-weight: bold;
  margin: 0;
  text-align: right;
}
@media (min-width: 992px) {
  .ui.popup.visible .content {
    min-width: 280px;
  }
}
@media (max-width: 400px) {
  .ui.popup.visible .content {
    max-width: 200px;
  }
}

.tooltip-container {
  padding: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  font-size: 14px;
  position: relative;
}

.tooltip-header {
  background-color: #0060AE;
  color: white;
  padding: 4px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.tooltip-header > span {
  margin-left: 6px;
}

.tooltip-body {
  padding: 4px 10px 4px 10px;
  background-color: #efefef;
  color: #4a4a4a;
}

.tooltip-selected {
  stroke: rgba(241, 133, 60, 0.4);
  stroke-width: 2px;
}

.other-charges-tooltip.ui.table {
  padding: 0;
  font-size: 12px;
  border-radius: 0px;
  border: none;
  box-shadow: inset 0 4px 7px -5px rgba(0, 0, 0, 0.7);
}

.usage-summary-table .other-charges-tooltip.ui.table td {
  padding-left: 25px;
  border-bottom: 1px #ddd solid;
}

.usage-summary-info-icon {
  position: absolute;
  right: 10px;
}
@media (max-width: 991px) {
  .usage-summary-info-icon {
    margin-top: -5px;
  }
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field.required.hide-errors .ui.input + small, .field.required.hide-labels label {
  display: none !important;
}

.ui.dimmer.page.page-load {
  background-color: #0060ae;
}
.ui.dimmer.page.page-load.active {
  opacity: 0.85 !important;
}

.topup-options .ui.button.grey.active {
  background-color: #0060ae;
}

.payment-other-value-input .ui.input {
  opacity: 1;
}

.business-payment-header {
  display: none;
}

.no-tabs-form {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
  padding: 1em;
  margin: 15px;
}
@media (min-width: 768px) {
  .no-tabs-form {
    margin: 0 45px 15px;
  }
}
.no-tabs-form .bills-list + .ui.header.business-payment-header {
  border-top: 1px solid #A1B0C3;
  margin: 1.25em -1em;
}
.no-tabs-form .ui.header.business-payment-header {
  display: block;
  text-align: center;
  font-weight: normal;
  color: #415879;
  padding-top: 11px;
  margin-top: 0;
}

.no-tabs-form .post-pay-header .ui.header,
.ui.form .post-pay-header .ui.header {
  font-weight: normal;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
  color: #0060AE;
}

.bills-list {
  margin-bottom: 10px;
}
.bills-list .bill-details {
  margin-bottom: 10px;
}
.bills-list .bill-details .row {
  padding: 0 0.5em;
  font-size: 14px;
  font-weight: 600;
  color: #415879;
  height: 35px;
  background-color: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bills-list .bill-details .row:first-of-type {
  background-color: rgba(216, 216, 216, 0.318416);
}
.bills-list .bill-details .pdf-link {
  cursor: pointer;
  opacity: 0.6;
  padding-left: 5px;
}

.business-only-header .ui.header {
  color: #415879;
  text-align: center;
  margin: 15px 0;
  font-weight: normal;
}
.business-only-header .label {
  display: block;
  text-align: center;
  color: #415879;
  padding-bottom: 11px;
  margin: -5px -26px 0;
}

.ui.modal.themed-modal {
  max-height: 100vh;
  overflow-x: hidden;
}
@media (max-width: 991px) {
  .ui.modal.themed-modal {
    overflow-y: scroll;
  }
  .ui.modal.themed-modal::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  .ui.modal.themed-modal * > .ui.image {
    max-height: 50vh;
  }
}
@media (max-width: 350px) {
  .ui.modal.themed-modal * > .ui.image {
    max-height: 40vh;
  }
}
@media (min-width: 992px) {
  .ui.modal.themed-modal {
    overflow-y: auto;
    background: white !important;
    color: inherit;
  }
  .ui.modal.themed-modal small {
    color: inherit !important;
  }
  .ui.modal.themed-modal .slick-dots li.slick-active button:before {
    color: black !important;
  }
  .ui.modal.themed-modal .slick-slide {
    padding-bottom: 1em;
  }
  .ui.modal.themed-modal .slick-slide p {
    margin: 0.75em 1em;
  }
  .ui.modal.themed-modal * > .ui.image {
    max-height: 60vh;
  }
}

.usage-graph-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}
.usage-graph-header .icon.angle {
  padding-top: 0.125em;
}
.usage-graph-header .icon.angle.left {
  margin-right: auto;
}
.usage-graph-header .icon.angle.right {
  margin-left: auto;
}
.usage-graph-header .ui.selection.dropdown {
  color: inherit;
  padding: 0.25rem 1rem;
  min-height: 1em;
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  margin-left: 0.25em;
}
.usage-graph-header .ui.selection.dropdown > .dropdown.icon {
  top: 0.2em;
  right: 0.333em;
}

.rmc-picker-content,
.rmc-picker-item {
  user-select: none;
}

.rmc-picker-mask {
  cursor: ns-resize;
}

.rmc-picker-item {
  color: black !important;
  font-size: 16px !important;
}

.rmc-picker-popup-item {
  color: #0060ae !important;
  width: 5em;
  font-size: 16px !important;
}

@media (max-width: 767px) {
  .usage-data-message .ui.grid .column:nth-child(3) {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .usage-data-message .ui.grid .column:nth-child(2) {
    text-align: left;
    padding-left: 0.5rem;
  }
}

@media (max-width: 991px) {
  .ui.padded.segment.main-wrapper.layout-default.transaction-history-layout {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.ui.buttons.transaction-history-desktop-tab-group {
  width: 100%;
  flex-direction: column;
}

.bill-segment-item:nth-child(odd) {
  background-color: #f8f8f8;
}

.bill-segment-item > div {
  display: flex;
  align-items: center;
}

.bill-segment-item > * > .bill-segment-date {
  padding: 1em;
  flex: 1;
}

.bill-segment-item > * > .bill-segment-view-button {
  background: transparent;
  color: #0060ae;
}

.bill-segment-view-link {
  padding-right: 1em;
}

.ui.items > .item > .content > .bill-transaction {
  margin-top: 13px;
  margin-bottom: 2px;
}

.transaction-description {
  overflow: hidden;
  overflow-wrap: break-word;
  font-weight: bold;
  padding-left: 0 !important;
  text-align: left !important;
}

.ui.items > .item.notification.transaction {
  margin-bottom: 10px !important;
  border-radius: 10px;
}

.payment-plan-link {
  display: block;
  text-decoration: underline;
  font-size: 0.75em;
  opacity: 0.75;
  margin-top: 10px;
}
@media (min-width: 991px) {
  .payment-plan-link {
    margin: 10px 0 -20px 0;
  }
}

p.advancePayments__content {
  font-size: 36px;
  font-family: graphik-medium;
  height: 30px;
}

.instalment__content-due {
  display: flex;
  justify-content: space-around;
}

.instalment__subheader-double {
  width: 50%;
  padding: 15px 0 30px 0;
}

.payment-help {
  text-align: center;
  padding: 2.2em 1.8em;
}
.payment-help .ui.button {
  border-radius: 1.5em;
}
.payment-help .header {
  font-weight: normal;
}
.payment-help + .divider {
  border-top: 1px solid rgb(134, 203, 223);
  margin: 0 -22px;
}
@media (min-width: 768px) {
  .payment-help {
    max-width: 500px;
    margin: auto;
  }
  .payment-help:first-of-type {
    padding-top: 10px;
  }
}
.payment-help .paymentPlanForm .field.plan-instalment-startdate label,
.payment-help .paymentPlanForm .field.plan-instalment-amount label,
.payment-help .paymentPlanForm .field.plan-instalment-heating label,
.payment-help .paymentPlanForm .field.plan-instalment-frequency > label {
  text-align: left;
  padding: 4px 0 6px 0;
}
.payment-help .paymentPlanForm .field.plan-instalment-startdate label:after,
.payment-help .paymentPlanForm .field.plan-instalment-amount label:after,
.payment-help .paymentPlanForm .field.plan-instalment-heating label:after,
.payment-help .paymentPlanForm .field.plan-instalment-frequency > label:after {
  margin-left: 0;
}
.payment-help .paymentPlanForm .required.field.plan-instalment-heating > label:after {
  display: none;
}
.payment-help .paymentPlanForm .ui.radio.checkbox.radioButton {
  background-color: #C4C4C4;
  flex-grow: 1;
  margin: 0;
  border-radius: 5px;
  padding: 0.65em 1em 0.65em;
}
.payment-help .paymentPlanForm .ui.radio.checkbox.radioButton.checked {
  background-color: #2673DD;
}
.payment-help .paymentPlanForm .ui.radio.checkbox.radioButton:nth-of-type(2) {
  margin: 0 10px;
}
.payment-help .paymentPlanForm .ui.radio.checkbox.radioButton label {
  padding-left: 0;
  text-align: center;
  font-weight: normal;
}
.payment-help .paymentPlanForm .ui.radio.checkbox.radioButton label:before, .payment-help .paymentPlanForm .ui.radio.checkbox.radioButton label:after {
  display: none;
}

.ui.cards > .card.card-animation-enter {
  opacity: 0.01;
}
.ui.cards > .card.card-animation-enter-active {
  opacity: 1;
  transition: opacity 750ms ease-in;
}

.ui.cards > .card.card-animation-leave {
  opacity: 1;
}
.ui.cards > .card.card-animation-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.child_card.card-animation-enter .card {
  opacity: 0.01;
}
.child_card.card-animation-enter-active .card {
  opacity: 1;
  transition: opacity 750ms ease-in;
}

.child_card.card-animation-leave .card {
  opacity: 1;
}
.child_card.card-animation-leave-active .card {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.paymentForeword {
  padding: 1.2rem 1.5rem 0.7rem 1.5rem;
}
@media (min-width: 768px) {
  .paymentForeword {
    padding-top: 0;
  }
}

.horizontal-panel-menu {
  margin: 15px;
}
@media (min-width: 768px) {
  .horizontal-panel-menu {
    margin: 20px 45px 15px;
  }
}
.horizontal-panel-menu .ui.menu {
  display: flex;
}
.horizontal-panel-menu .ui.menu .item, .horizontal-panel-menu .ui.menu .item:hover {
  justify-content: center;
  background-color: #0060AE;
  color: white;
  border-radius: 6px 6px 0 0 !important;
  flex: 1 1 0px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
}
.horizontal-panel-menu .ui.menu .item span, .horizontal-panel-menu .ui.menu .item:hover span {
  padding-bottom: 5px;
}
.horizontal-panel-menu .ui.menu .item:nth-of-type(2n), .horizontal-panel-menu .ui.menu .item:hover:nth-of-type(2n) {
  margin-left: 0.5%;
  margin-right: 0.5%;
}
.horizontal-panel-menu .ui.menu .item.active, .horizontal-panel-menu .ui.menu .item.active:hover, .horizontal-panel-menu .ui.menu .item:hover.active, .horizontal-panel-menu .ui.menu .item:hover.active:hover {
  background-color: white;
  color: #0060AE;
}
.horizontal-panel-menu .ui.menu .item.full-width, .horizontal-panel-menu .ui.menu .item:hover.full-width {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.horizontal-panel-menu .payment-form {
  padding: 0px;
  color: #2d373e;
}
@media (min-width: 768px) {
  .horizontal-panel-menu .payment-form {
    padding: 10px;
  }
}
.horizontal-panel-menu .payment-form label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  color: #000000 !important;
}
.horizontal-panel-menu .payment-form p {
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.5;
  color: #000000;
}
.horizontal-panel-menu .payment-form a {
  color: #1e70bf;
  text-decoration: underline;
}
.horizontal-panel-menu .payment-form a:hover {
  color: #0f4b78;
}

.checkboxWithLinks {
  color: black;
  margin: 2rem 1.2rem;
  padding: 8px 0;
  display: flex;
  justify-content: center;
}
.checkboxWithLinks a {
  font-weight: bold;
  color: black;
}
.checkboxWithLinks .link {
  line-height: 1rem;
  text-align: left;
  font-size: 12px;
}
.checkboxWithLinks .ui.checkbox {
  min-height: 20px;
  line-height: 20px;
  min-width: 30px;
  margin: 0 10px;
}
.checkboxWithLinks .ui.checkbox .box:before, .checkboxWithLinks .ui.checkbox label:before {
  border-radius: 10%;
  width: 30px;
  height: 30px;
}
.checkboxWithLinks .ui.checkbox .box:after,
.checkboxWithLinks .ui.checkbox label:after {
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .checkboxWithLinks {
    align-items: baseline;
  }
}

.direct-debit-form.disabled .ui.form .disabled.field,
.direct-debit-form.disabled .ui.form .disabled.fields .field,
.direct-debit-form.disabled .ui.form .field :disabled,
.direct-debit-form.disabled .ui.header,
.direct-debit-form.disabled svg,
.autopayment-form.disabled .ui.form .disabled.field,
.autopayment-form.disabled .ui.form .disabled.fields .field,
.autopayment-form.disabled .ui.form .field :disabled,
.autopayment-form.disabled .ui.header,
.autopayment-form.disabled svg,
.topup-form.disabled .ui.form .disabled.field,
.topup-form.disabled .ui.form .disabled.fields .field,
.topup-form.disabled .ui.form .field :disabled,
.topup-form.disabled .ui.header,
.topup-form.disabled svg {
  opacity: 0.8;
}
.direct-debit-form .ui.form .disabled.field, .direct-debit-form .ui.form .disabled.fields .field, .direct-debit-form .ui.form .field :disabled,
.autopayment-form .ui.form .disabled.field,
.autopayment-form .ui.form .disabled.fields .field,
.autopayment-form .ui.form .field :disabled,
.topup-form .ui.form .disabled.field,
.topup-form .ui.form .disabled.fields .field,
.topup-form .ui.form .field :disabled {
  opacity: 0.8;
}
.direct-debit-form .ui.form .field.disabled > label, .direct-debit-form .ui.form .fields.disabled > label, .direct-debit-form .ui.disabled.input, .direct-debit-form .ui.input:not(.disabled) input[disabled],
.autopayment-form .ui.form .field.disabled > label,
.autopayment-form .ui.form .fields.disabled > label,
.autopayment-form .ui.disabled.input,
.autopayment-form .ui.input:not(.disabled) input[disabled],
.topup-form .ui.form .field.disabled > label,
.topup-form .ui.form .fields.disabled > label,
.topup-form .ui.disabled.input,
.topup-form .ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}
.direct-debit-form .ui.header,
.autopayment-form .ui.header,
.topup-form .ui.header {
  color: rgb(0, 96, 174);
  font-weight: normal;
  text-align: center;
  margin-top: 0;
  padding: 15px;
}
.direct-debit-form .ui.form .field > label:not(.link),
.autopayment-form .ui.form .field > label:not(.link),
.topup-form .ui.form .field > label:not(.link) {
  color: #0060AE;
  margin: 0 0 0.5rem 0;
}
.direct-debit-form .ui.form .checkboxWithLinks,
.autopayment-form .ui.form .checkboxWithLinks,
.topup-form .ui.form .checkboxWithLinks {
  align-items: center;
}
.direct-debit-form .ui.form .checkboxWithLinks .link,
.autopayment-form .ui.form .checkboxWithLinks .link,
.topup-form .ui.form .checkboxWithLinks .link {
  margin: 0;
  width: 70%;
  font-size: 12px;
}
.direct-debit-form .ui.form .checkboxWithLinks .field,
.autopayment-form .ui.form .checkboxWithLinks .field,
.topup-form .ui.form .checkboxWithLinks .field {
  margin-bottom: 0;
}
.direct-debit-form .ui.grey.button,
.autopayment-form .ui.grey.button,
.topup-form .ui.grey.button {
  background-color: #C4C4C4;
  padding: 0.5em 1em 0.5em;
}
.direct-debit-form .outstanding-bill .button,
.autopayment-form .outstanding-bill .button,
.topup-form .outstanding-bill .button {
  background-color: #E8E8E8;
  color: #0060AE;
}
.direct-debit-form .outstanding-bill .button.ui.active,
.autopayment-form .outstanding-bill .button.ui.active,
.topup-form .outstanding-bill .button.ui.active {
  background-color: #0060AE;
  color: white;
}
.direct-debit-form .active-header,
.autopayment-form .active-header,
.topup-form .active-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.direct-debit-form .active-header .ui.header,
.autopayment-form .active-header .ui.header,
.topup-form .active-header .ui.header {
  margin: 0;
  font-size: 18px;
}
.direct-debit-form .active-body,
.autopayment-form .active-body,
.topup-form .active-body {
  width: 100%;
  margin: 15px -1em;
  width: calc(100% + 2em);
  text-align: center;
}
.direct-debit-form .accounts-list,
.autopayment-form .accounts-list,
.topup-form .accounts-list {
  margin-top: 0;
}
.direct-debit-form .error-header,
.autopayment-form .error-header,
.topup-form .error-header {
  text-align: center;
  color: red;
  padding: 0.25em 0px;
  display: block;
}

.topup-link {
  text-align: center;
  margin: 0 0 1em;
}

.innerCard {
  background-color: white;
  margin: 1rem 0;
}
.innerCard .text {
  color: white;
  margin: 0;
  padding: 2px;
  border-radius: 4px 4px 0 0;
}
.innerCard .statistic {
  margin: 0;
  border-radius: 0 0 4px 4px;
}
.innerCard .statistic .value {
  padding: 1rem 0rem;
}
.innerCard + .ui.button {
  display: block;
  margin: auto;
}
@media (min-width: 768px) {
  .innerCard {
    display: inline-block;
    margin: 1rem;
  }
  .innerCard .statistic .value {
    padding: 1rem 2rem;
  }
}

.business-form .ui.header {
  color: #415879;
}
.business-form .ui.form .field > label:not(.link) {
  color: #415879;
}
.business-form .outstanding-bill .button {
  background-color: #E8E8E8;
  color: #415879;
}
.business-form .outstanding-bill .button.ui.active {
  background-color: #415879;
  color: white;
}

.topup-link {
  text-align: center;
  margin: 0 0 1em;
}

.innerCard {
  background-color: white;
  margin: 1rem 0;
}
.innerCard .text {
  color: white;
  margin: 0;
  padding: 2px;
  border-radius: 4px 4px 0 0;
}
.innerCard .statistic {
  margin: 0;
  border-radius: 0 0 4px 4px;
}
.innerCard .statistic .value {
  padding: 1rem 0rem;
}
.innerCard + .ui.button {
  display: block;
  margin: auto;
}
@media (min-width: 768px) {
  .innerCard {
    display: inline-block;
    margin: 1rem;
  }
  .innerCard .statistic .value {
    padding: 1rem 2rem;
  }
}

.ui.card > .content .cardBlock-top p,
.ui.card > .content .cardBlock-bottom p {
  margin-bottom: 0;
}

.cardBlock-top, .cardBlock-bottom {
  text-transform: none;
  width: 50%;
  display: inline-block;
  padding: 10px 0;
}
.cardBlock-top {
  border-top: 1px solid lightGrey;
  border-right: 1px solid lightGrey;
}
.cardBlock-bottom {
  border-top: 1px solid lightGrey;
}

/* Generic bordering for any element - we never remove borders (for spacing purposes), instead we change it's colour */
.bordered {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(180, 180, 180);
}
.bordered.thin {
  border-width: 1px;
}
.bordered.thick {
  border-width: 3px;
}
.bordered.top, .bordered.bottom, .bordered.left, .bordered.right {
  border-color: transparent;
}
.bordered.top {
  border-top-color: rgb(180, 180, 180);
}
.bordered.bottom {
  border-bottom-color: rgb(180, 180, 180);
}
.bordered.left {
  border-left-color: rgb(180, 180, 180);
}
.bordered.right {
  border-right-color: rgb(180, 180, 180);
}

.slick-track {
  transition: transform 500ms ease 0s;
}

/* General styles for the In App Signup flow */
.in-app-signup {
  color: rgba(0, 0, 0, 0.87);
}

.in-app-signup,
.in-app-signup .ui.form,
.in-app-signup p {
  font-size: 17px;
  line-height: 2;
}

.signup-header,
.marketing-header {
  margin-top: 104px;
}
.signup-header h1,
.marketing-header h1 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 40px;
}

.marketing-layout .ui.input input,
.logged-out-layout .ui.input input,
.in-app-signup .ui.input input {
  border: 1px solid #A09B9B;
  padding: 15px 15px;
}
.marketing-layout .ui.selection.dropdown,
.logged-out-layout .ui.selection.dropdown,
.in-app-signup .ui.selection.dropdown {
  border: 1px solid #A09B9B;
  padding: 15px 15px;
  line-height: 1.21428571em;
}
.marketing-layout .ui.selection.dropdown > .dropdown.icon,
.logged-out-layout .ui.selection.dropdown > .dropdown.icon,
.in-app-signup .ui.selection.dropdown > .dropdown.icon {
  margin-top: 0;
  margin-bottom: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ui.basic.selection.dropdown {
  display: flex;
  align-items: center;
  color: inherit;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 7px;
  padding: 0.6875em 0.875em;
  min-height: 50px;
  text-align: left;
  font-size: 15px;
}
.ui.basic.selection.dropdown.active {
  box-shadow: none;
}
.ui.basic.selection.dropdown.disabled {
  opacity: 0.95;
}
.ui.basic.selection.dropdown svg {
  margin-right: 12px;
  line-height: 0;
}
.ui.basic.selection.dropdown > .text {
  flex: 1 1 0;
  color: inherit;
  font-weight: normal;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.ui.basic.selection.dropdown > .text:not(.default) {
  color: inherit;
}
.ui.basic.selection.dropdown > .icon {
  margin: 0 0 0 auto;
  top: initial;
  color: inherit;
}
.ui.basic.selection.dropdown > .menu {
  max-height: none;
  border: 1px solid #F2F2F2;
  border-radius: 0;
  min-width: 100%;
  background: inherit;
  box-shadow: 0px 2px 3px 0px rgba(128, 128, 128, 0.1);
}
.ui.basic.selection.dropdown > .menu .item {
  display: flex;
  align-items: center;
  padding: 0.6875em 0.875em !important;
  border-top: 1px solid rgba(128, 128, 128, 0.3);
  font-size: inherit;
  color: inherit;
  min-height: 50px;
}
.ui.basic.selection.dropdown > .menu .item:hover {
  background: rgba(128, 128, 128, 0.1);
}
.ui.basic.selection.dropdown > .menu .item.selected {
  display: none !important;
}

.contactUsPage {
  margin-top: 20px;
}

.tab-button-group {
  width: 100%;
}
@media (min-width: 992px) {
  .tab-button-group {
    width: 400px;
  }
}

.ui.buttons > .ui.button.tab-button {
  flex: 1;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-weight: normal;
}
.ui.buttons > .ui.button.tab-button.active {
  font-weight: bold;
}

.tab-pane {
  border-top-left-radius: 0px;
}
.tab-pane > .ui.segment {
  border: none;
  box-shadow: none;
}

/*
//This should be refactored later so there's not so much duplication, we are reusing the usage
  //screen tab buttons in the bill segments screen
  .ui.buttons.tab-button-group > .tab-button {
    flex: 1;
    border-radius: 0px;
    border: 1px solid rgba(34,36,38,.15);
    border-bottom: none;
    background-color: #002963;
    color: white;
    font-weight: normal;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &.tab-button-active {
      background-color: white;
      color: #0060ae;
      font-weight: bold;
    }
  }

  .ui.buttons.transaction-history-desktop-tab-group > .tab-button {
    flex: 1;
    min-height: 2.5em;
    border-radius: 0px;
    border: 1px solid rgba(34,36,38,.15);
    border-bottom: none;
    background-color: #002963;
    color: white;
    font-weight: normal;

    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.tab-button-active {
      background-color: white;
      color: #0060ae;
      font-weight: bold;
    }
  }
	*/
.ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: var(--alternate-background) !important;
}

.notifications-cage {
  margin: 0 -1rem 0;
  padding: 0.6rem 1rem 1rem;
  border-radius: 0.5rem;
}
.notifications-cage > .ui.header {
  margin: -0.6rem -1rem 1rem;
  padding: 0.6rem 1rem 0.5rem;
  font-size: 15px;
  font-weight: normal;
}

.ui.items > .item.notification > .content {
  background: none;
}

.help .ui.vertical.menu {
  background: none;
}
.help .ui.menu .item {
  color: inherit;
}
.help .ui.menu .item:hover {
  color: inherit !important;
}
.help .ui.menu .item.active {
  color: #f7902b;
}
.help .ui.menu .item.active:active, .help .ui.menu .item.active:hover {
  color: #f7902b !important;
}
.help .ui.styled.accordion .title {
  color: inherit;
}

body {
  font-family: "graphik", Arial, Helvetica, sans-serif;
}

.ui.statistic > .value, .ui.statistics .statistic > .value {
  font-family: "graphik-medium", Arial, Helvetica, sans-serif;
}

.ui.card,
.ui.cards > .card {
  box-shadow: none;
  border-radius: 5px;
}
.ui.card > .header,
.ui.cards > .card > .header {
  border-bottom: none;
  background: none;
  color: inherit;
  font-family: "graphik-medium";
  text-align: left;
  padding: 15px;
  font-size: 14px;
}
.ui.card > .content,
.ui.cards > .card > .content {
  border-top: none;
  background: none;
  color: inherit;
}
.ui.card .ui.statistic > .value,
.ui.cards > .card .ui.statistic > .value {
  color: inherit;
}

.ui.header {
  color: inherit;
  font-family: "graphik-medium";
}

.ui.secondary.inverted.menu .link.item,
.ui.secondary.inverted.menu a.item {
  color: inherit !important;
}

.ui.modal > .header, .ui.modal > .content, .ui.modal > .actions {
  background: none;
  color: inherit;
}

/*# sourceMappingURL=App.css.map */
